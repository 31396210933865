import React from "react"
import styled from "@emotion/styled"

const StyledFooter = styled.footer`
  color: white;
  background-color: #022169;
  width: 100%;
  padding: 15px;
  text-align: center;
  margin-top: 15px;

  a {
     color: gray;
     text-decoration: none;
  }

  p {
     padding: 0px 15px;
  }
`


const Footer = () => {
   var year = new Date().getFullYear();

   return(
      <StyledFooter>
        <p>&copy;{`${year}`} Henkel Corporation. All Rights Reserved</p>
          <p>
          <a href="https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank">Privacy Policy</a> | <a href="https://www.henkel-northamerica.com/ca-privacy-rights?view=content-blank">CA Privacy Rights</a> | <a href="https://www.henkel-northamerica.com/terms-of-use-na?view=content-blank">Terms of Use</a> | <a href="https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank#analytics">About Ads</a> | <a href="https://henkelprivacy.exterro.net/portal/dsar.htm?target=henkelprivacy">Do Not Sell or Share My Personal Information</a>
          </p>
       </StyledFooter>
   )
}

export default Footer
